import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import Font from "../../../../utils/FontSize";
import GitHubIcon from "@material-ui/icons/GitHub";
import SocialIcon from "../../../../utils/SocialIcon";
import DiscordIcon from "../../../../assets/privacyfocused/discord.svg";
import { DESKTOP_LINUX, DESKTOP_MAC, DESKTOP_WINDOWS } from "../../../../Ghost";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: Font.xl,
    fontWeight: "bold",
    lineHeight: 1.2,
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size40,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: 450,
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: 330,
      fontSize: Font.size28,
    },
  },
  title2: {
    fontSize: Font.xl,
    fontWeight: "normal",
    width: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size40,
    },
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: Font.size28,
    },
  },
  description: {
    fontSize: Font.m,
    width: 430,
    color: theme.palette.secondary.textColor,
    paddingTop: 50,
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size16,
      width: 380,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      width: 300,
      paddingTop: 30,
    },
  },
  btnarea: {
    marginTop: 50,
    display: "flex",
    flexFlow: 'column',
    marginLeft: -16,
    [theme.breakpoints.down("sm")]: {
      flexFlow: 'row',
      justifyContent: "center",
      marginTop: '35px',
      marginLeft:'-8px'
    },
  },
  btnLink: {
    [theme.breakpoints.down("sm")]: {
      '& button': {
        marginTop: '15px'
      }
    },
  },
  btnsWrapper: {
    marginTop: '15px',
    [theme.breakpoints.down("sm")]: {
      marginTop: '0px',
    },
  },
  btnCommon: {
    width: '196px',
    height: '48px',
    borderRadius: 8,
    marginLeft: 16,
    textTransform: 'capitalize',
    [theme.breakpoints.down("md")]: {
      width: '180px',
      fontSize: Font.size12,
    },
    [theme.breakpoints.down("xs")]: {
      width: '160px',
      fontSize: Font.size10,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft:'8px'
    },
  },
  downloadBtn: {
    border: "1px solid #FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.15)",
    },
  },
  socialIconGroup: {
    marginTop: 40,
  },
}));

const PrivacyLeftSide = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title}>
        Privacy-Focused &amp;{" "}
        <span className={classes.title2}>Decentralized Ecosystem</span>
      </Typography>
      <Typography className={classes.description}>
        Proof of Stake privacy coin to help make you nothing but a "ghost" when
        transacting online!
      </Typography>
      <div className={classes.btnarea}>
        <div>
          <a href="https://github.com/ghost-coin" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }} className={classes.btnLink}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnCommon}
            >
              <GitHubIcon style={{ marginRight: 10, }} />
              Ghost Github
          </Button>
          </a>
          <a href={DESKTOP_WINDOWS.link} download style={{ textDecoration: "none" }} className={classes.btnLink}>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.btnCommon} ${classes.downloadBtn}`}>
              {DESKTOP_WINDOWS.title}
          </Button>
          </a>
        </div>
        <div className={classes.btnsWrapper}>
          <a href={DESKTOP_LINUX.link} download style={{ textDecoration: "none" }} className={`${classes.btnLink} ${classes.btnLinkSeparator}`}>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.btnCommon} ${classes.downloadBtn}`}>
              {DESKTOP_LINUX.title}
          </Button>
          </a>
          <a href={DESKTOP_MAC.link} download style={{ textDecoration: "none" }} className={`${classes.btnLink} ${classes.btnLinkSeparator}`}>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.btnCommon} ${classes.downloadBtn}`}>
              {DESKTOP_MAC.title}
          </Button>
          </a>
        </div>
      </div>
      <div className={classes.socialIconGroup}>
        <SocialIcon DiscordIcon={DiscordIcon} />
      </div>
    </div>
  );
};

export default PrivacyLeftSide;
