import React, { Fragment } from "react";
import Footer from "../Footer";
import RoadMapWrapper from "./RoadMapWrapper";
import FooterDivider from "../../utils/FooterDivider";

const RoadMap = () => {
  return (
    <Fragment>
        <RoadMapWrapper />
        <FooterDivider />
        <Footer />
    </Fragment>
  );
};

export default RoadMap;
