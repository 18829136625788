import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, CardMedia } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import RedditIcon from "@material-ui/icons/Reddit";
import TelegramIcon from "@material-ui/icons/Telegram";

const useStyles = makeStyles((theme) => ({
    discordIcon: {
        height: 22,
        width: 25,
      },
      socialIconGroup: {
        marginTop: 55,
      },
      socialIcon: {
        color: theme.palette.secondary.socialIconColor,
        fontSize: 25,
      },
}));

const SocialIcon = (props) => {
  const classes = useStyles();
  const buttonPadding = props.compact ? 0 : 12;
  return (
    <div>
      <a href = "https://twitter.com/GhostbyMcAfee" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding, paddingLeft: buttonPadding}}>
          <TwitterIcon className={classes.socialIcon} />
        </IconButton>
      </a>
      <a href = "https://discord.gg/Pjbme6v" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding}}>
          <CardMedia
            className={classes.discordIcon}
            image={props.DiscordIcon}
            title="DiscordLogo"
          />
        </IconButton>
      </a>
      <a href = "https://www.reddit.com/r/GhostPrivacyCoin/" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding}}>
          <RedditIcon className={classes.socialIcon} />
        </IconButton>
      </a>
      <a href = "https://t.me/ghostcoinbymcafee" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding}}>
          <TelegramIcon className={classes.socialIcon} />
        </IconButton>
      </a>
    </div>
  );
};

export default SocialIcon;
