import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import ghostRoadMapImg from "../../assets/ghostRoadMap/ghostRoadMapImg.svg";
import Font from "../../utils/FontSize";
import RoadMapTabs from "./RoadMapTabs";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 26,
    paddingBottom: 100,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 26,
    }
  },
  title: {
    fontSize: Font.xl,
    fontWeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size40,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
      width: 'auto'
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: Font.size28,
    },
  },
  title2: {
    fontSize: Font.xl,
    fontWeight: "bold",
    lineHeight: 1.2,
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size40,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: Font.size28,
    },
  },
  ghostRoadMapImg: {
    width: 150,
    display: 'block',
    marginLeft: 'auto',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const RoadMapWrapper = () => {
  const classes = useStyles();
  return (
      <div className={classes.mainContainer}>
        <div className="road-map-container">
          <Grid container style={{alignItems: 'center'}}>
            {/* <Grid item md={6} sm={12} xs={12}> */}
            <Grid item md={8} sm={12} xs={12}>
              <Typography className={classes.title}>
                Ghost {" "}<span className={classes.title2}>Roadmap 2022</span>
              </Typography>
            </Grid>
            {/* <Grid item md={6} sm={12} xs={12}> */}
            <Grid item md={4}>
              <img src={ghostRoadMapImg} alt="Ghost Road Map Img" className={classes.ghostRoadMapImg}/>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <RoadMapTabs />
          </Grid>
        </div>
      </div>
  );
};

export default RoadMapWrapper;
