import React from "react";
import {Grid} from "@material-ui/core";
import {FaqContainer} from "./index";

const FaqExchange = () => {
  return (
      <Grid container>
        <FaqContainer title="Where can I buy Ghost?">
            The exchanges to purchase mainnet Ghost are listed at the top of the homepage.
        </FaqContainer>
        <FaqContainer title="What wallet can I use for Ghost?">
            Currently you can download the official Ghost Wallet here, <a style={{ color: "#AEFF00" }} href="https://github.com/ghost-coin/ghost-desktop/releases">Ghost Wallet</a>
        </FaqContainer>
        <FaqContainer title="Where is the mobile wallet?">
            You can use the <b>Hebe Wallet</b> on Android/iOS devices.
            Staking for $GHOST will be supported, but will be implemented at a later date.
            To learn more about Hebe, visit <a style={{ color: "#AEFF00" }} href="https://hebe.cc/ghost/ghost.html">their website</a>.
        </FaqContainer>
        <FaqContainer title="Does the Ghost wallet update itself?">
            No. But the wallet updates the latest core automatically. You will need to update the main wallet client yourself by downloading the new version as it's released.
        </FaqContainer>
      </Grid>
  );
};

export default FaqExchange;
