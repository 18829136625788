import React from "react";
import {Grid} from "@material-ui/core";
import {FaqContainer} from "./index";

const FaqStaking = () => {
  return (
      <Grid container>
        <FaqContainer title="How does Staking work?">
            Here is a link to a really well written explanation of Proof of Stake,{" "}
            <a style={{ color: "#AEFF00" }} href="https://www.bitdegree.org/tutorials/proof-of-work-vs-proof-of-stake/">Proof of Work VS Proof of Stake</a>
        </FaqContainer>
        <FaqContainer title="My coins are not being staked, why?">
            Public inputs (ghost coins) needs to have at least 225 confirmations before they become eligible for staking. This generally takes between 5-8hrs.
        </FaqContainer>
        <FaqContainer title="Will I be penalized if I stop staking?">
            No you will not be penalized if you stop staking via the wallet or cold staking.
        </FaqContainer>
        <FaqContainer title="Is staking safe?">
            Yes, hot staking within the wallet is safe however it’s recommended to cold stake if possible.
        </FaqContainer>
        <FaqContainer title="Why is my staking weight/time changing?">
            Your staking weight will fluctuate as more individuals stake their funds or remove their funds from being staked. In essence it shows how much your weight encompasses the network stakeweight.
        </FaqContainer>
        <FaqContainer title="How often will I get rewards for staking?">
            The amount you will receive rewards depends on how many Ghost you have. There is a feature on the Ghost wallet that provides you with an estimated reward time.
            If you have less than 1000 Ghost coins it’s recommended that you look into joining a pool as you will earn more consistent rewards this way.
        </FaqContainer>
        <FaqContainer title="What is Cold Staking and how do I set it up?">
            We’ve created a cold staking guide that you can read through, here{" "}
            <a style={{ color: "#AEFF00" }} href="https://ghostveterans.com/vps/">Cold Staking Guide</a>
        </FaqContainer>
      </Grid>
  );
};

export default FaqStaking;
