import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import LatestNews from "./components/News/index";
import UserGuide from "./components/UserGuide";
import Faq from "./components/FAQ";
import RoadMap from "./components/RoadMap";
import ScrollToTop from "./utils/ScrollToTop";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#16161A",
      textColor: "#FFFFFF",
    },
    secondary: {
      // main: "#EF4565",
      main: '#AEFF00',
      textColor: "#94A1B2",
      socialIconColor: "#72757E",
    },
  },
  typography: {
    fontFamily: ["ProximaNovaRegular"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: '#16161A',
        "&:hover": {
          color: '#fff'
        }
      }
    }
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Router>
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route exact component={Home} path="/" />
            <Route exact component={LatestNews} path="/news" />
            <Route exact component={UserGuide} path="/userguide" />
            <Route exact component={RoadMap} path="/roadmap" />
            <Route component={Faq} path="/faq" />
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
