import React from "react";
import {Grid} from "@material-ui/core";
import {FaqContainer} from "./index";

const FaqVeteran = () => {
  return (
      <Grid container>
        <FaqContainer title='What is "Ghost Veterans"?'>
            To be eligible to become a Ghost Veteran you must have a balance that is at least 20,000 GHOST in your wallet.
            Additionally these funds can not be spent within a 30 day period.
            If both these criteria are met you will receive your share of a reward from the Ghost Veteran Reward pool with a multiplier based on the increments of 20,000 GHOST that you have.
        </FaqContainer>
        <FaqContainer title="What is the Ghost Veteran Reward pool?">
            The Ghost Veteran Reward (GVR) pool is an accumulation of GHOST coins over a 30 day period that are dispersed to eligible Ghost Veterans. Currently 6 GHOST are added to this pool every block, or roughly every 2 minutes.
        </FaqContainer>
        <FaqContainer title="Can we lower the amount of Ghost needed to become a Ghost Veteran?">
            Yes, Ghost is a decentralized community project. Soon there will be a bounty website where proposals can be voted on by the community.
        </FaqContainer>
        <FaqContainer title="Can Ghost Veterans stake without losing their rewards?">
            Yes, staking does not affect your Ghost Veteran status, only sending funds directly to another address would disqualify you.
        </FaqContainer>
      </Grid>
  );
};

export default FaqVeteran;
