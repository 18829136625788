import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FontSize from "../../utils/FontSize";
import whitepaper from "../../assets/whitepaper/Ghost_by_McAfee_Whitepaper.pdf";
import {Link} from "react-router-dom";
import logo from "../../assets/ghostlogo-official.png";
import SocialIcon from "../../utils/SocialIcon";
import DiscordIcon from "../../assets/privacyfocused/discord.svg";

const useStyles = makeStyles((theme) => ({
  navContainer: {
    margin: "auto",
    maxWidth: 1110,
    minHeight: 80,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      padding: "0 10px",
    },
  },
  logoArea: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: 150,
  },
  logoText: {
    marginLeft: 17,
    fontSize: FontSize.size25,
  },
  description: {
    width: 170,
    fontSize: FontSize.size16,
    color: "rgba(255, 255, 255, 0.6)",
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("s")]: {
      width: 'auto',
    },
    [theme.breakpoints.down("xs")]: {
      width: 'auto',
    },
  },
  title: {
    width: 160,
  },
  navbarText: {
    color: "rgba(255, 255, 255, 0.6)",
    textDecoration: "none",
    paddingTop: 10,
  },
  footerInner: {
    width: 200,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      width: 400,
    },
  }
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <div style={{ ...props.style }}>
      <div className={classes.navContainer}>
        <Grid container align="center" justify="center" direction="row">
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <div className={classes.logoArea}>
                <img alt="Ghost Logo" src={logo} className={classes.logo} />
              </div>
              <Typography className={classes.description}>
                Privacy focused proof of stake cryptocurrency
                <Typography><a  style={{color: '#ffffff'}} href="mailto:Contact@GhostbyMcAfee.com">Contact@GhostbyMcAfee.com</a></Typography>
              </Typography>
              <Typography className={classes.title}>GHOST Community</Typography>
              <div className={classes.social}>
                <SocialIcon DiscordIcon={DiscordIcon} compact={true} />
              </div>
            </div>
            <br/>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <Typography className={classes.title}>GHOST Starter pack</Typography>
              <div className={classes.nav}>
                <a href = "/#/news" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>Latest News</Typography>
                </a>
                <a href = "/#" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>Status Report (soon)</Typography>
                </a>
                <a href = "https://ghostveterans.com/buy/" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>Get GHOST</Typography>
                </a>
                <a href = "/#" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>Ghost Wiki (soon)</Typography>
                </a>
              </div>
              <br/>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <Typography className={classes.title}>Trade GHOST on</Typography>
              <div className={classes.nav}>
                <a href = "https://www.hotbit.io/exchange?symbol=GHOST_USDT" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>HotBit</Typography>
                </a>
                <a href = 'https://tradeogre.com/exchange/BTC-GHOST' rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>TradeOgre</Typography>
                </a>
                <a href = "https://stakecube.net/app/exchange/GHOST_BTC" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>StakeCube</Typography>
                </a>
                <a href = "https://ghostx.live/" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>GhostX</Typography>
                </a>
              </div>
              <br/>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <Typography className={classes.title}>Join the movement</Typography>
              <div className={classes.nav}>
                <a href = "https://ghostscan.io" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>Block Explorer</Typography>
                </a>
                <a href = "https://github.com/ghost-coin" rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>Github</Typography>
                </a>
                <a href = {whitepaper} rel="noreferrer" target = "_blank" className={classes.navbarText}>
                  <Typography>Whitepaper</Typography>
                </a>
                <Link to="/userguide" className={classes.navbarText}>
                  <Typography>User Guides</Typography>
                </Link>
                <Link to="/faq" className={classes.navbarText}>
                  <Typography>FAQ</Typography>
                </Link>
              </div>
              <br/>
            </div>
          </Grid>
        </Grid>
      </div>
      <br/>
    </div>
  );
};

export default Footer;
