import React from "react";

// MUI stuff
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
} from "@material-ui/core";

import ghostRoadMapTabs from "../../assets/ghostRoadMap/ghostRoadMapTabs.svg";
import ghostRoadMapTabsAdaptive from '../../assets/ghostRoadMap/ghostRoadMapTabsAdaptive.svg'

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: 44,
    paddingBottom: 22,
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${ghostRoadMapTabs})`,
      backgroundPosition: 'center 122px',
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
      backgroundImage: `none`
    },
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 229,
    paddingLeft: 61,
    '&:first-child': {
      height: 122,
      marginBottom: 22
    },
    '&:nth-child(even)':{
      paddingLeft: 245
    },
    [theme.breakpoints.down("sm")]: {
      position: 'relative',
      alignItems: 'center',
      paddingLeft: 0,
      height: 'auto',
      marginBottom: 50,
      '&:first-child': {
        height: 'auto',
        marginBottom: 40
      },
      '&:nth-child(even)': {
        paddingLeft: 0,
      },
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        width: 400,
        height: 46,
        backgroundImage: `url(${ghostRoadMapTabsAdaptive})`,
      },
    }

  },
  title: {
    fontSize: 20,
    width: 568,
    color: "#AEFF00",
    lineHeight: '25px',
    [theme.breakpoints.down("sm")]: {
      width: 400,
    }
  },
  description: {
    letterSpacing: 0.5,
    fontSize: 14,
    color: theme.palette.secondary.textColor,
    width: 568,
    marginTop: 5,
    lineHeight: '18px',
    [theme.breakpoints.down("sm")]: {
        marginBottom: 60,
        width: 400,
    }
  }
}));

const arr = [
    {
        title: "Automated Ghost Veteran Rewards (Completed)",
        description:
            "This will replace the old, centralized system which required a third party and involved a tedious manual process to distribute the Veteran rewards to the community.\n" +
            "A simple public to public transaction will allow you to receive your Ghost Veteran rewards directly from the blockchain"
    },
    {
        title: "On-Chain Governance (Planned)",
        description:
            "Voting is a crucial requirement for any project to claim decentralization. The old way of voting was subject to manipulation and is difficult to track due to third-party " +
            "reliance. On-chain voting allows users to propose new ideas and suggest improvements to the Ghost ecosystem straight from the core wallet. Users having\n" +
            "a say in how the project moves forward gives Ghost a high level of transparency and direction, which is required within this space."
    },
    {
        title: "Dandelion++ (Completed)",
        description:
            "Dandelion++ is a lightweight network solution designed to help obfuscate users IPs while transacting. " + 
            "The protocol has various solutions built in to overcome known attack vectors such as Graph-learning, Intersecting, Black-hole, and Partial deployment to name a few. " + 
            "Ghost will be the first and only blockchain based on PoS to house both RingCT and Dandelion++. This will add an unmatched layer of both network privacy and security."
    },
    {
        title: "Hardware Wallet (In Progress)",
        description:
            "The integration of Ghost within a hardware wallet is a crucial step in improving the safety of Ghost funds held in long term storage. Users will be able " +
            "to receive staking rewards without risking funds on a virus-prone computer. Ghost is pursuing relationships with several key players in the industry."
    },
    {
        title: "GHOST-STABLECOIN (Planned)",
        description:
            "Ghost-Stablecoin will be the worlds first private stable coin pegged to the U.S. Dollar. Fees earned from these transactions will be distributed directly to GHOST holders. " +
            " You will easily be able to shop with confidence knowing your purchases are private."
    },
    {
        title: "Wrapped Ghost (Planned)",
        description:
            "Wrapped Ghost will give everyone access to billions of dollars of liquidity. This will allow Ghost to be traded on various platforms and eventually integrated " +
            "with DeFi apps like Aave, Maker, and more. Wrapped Ghost could be minted by burning mainnet Ghost, or vice versa, creating a 2-way bridge between the Ghost and the other chain."
    },
    {
        title: "iOS/Android Mobile Wallet App (Planned)",
        description:
            "This will be a mobile app made specifically for the Ghost community available in the Android & iOS app stores. You will be able to operate this wallet just like the desktop" +
            "versions."
    },
    {
        title: "P2P Marketplace (Planned)",
        description:
            "Take control over your business and finances with a P2P marketplace. Listings and purchases will be made simple with user-friendly features that are flexible to your needs. " +
            "Decide when, where, and how you want your product bought, with fees starting under 1%."
    },
    {
        title: "Encrypted GhostChat (In Progress)",
        description:
            "An integrated chat where you can communicate with your friends, buyers, sellers, and the Ghost community free of prying eyes. Eventually, this will become the " +
            "preferred method of communication within the Ghost community. GhostChat will have full AES-256 encryption and will be integrated directly into the wallet. "
    }
];

const RoadMapTabs = () => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      {arr.map((data, index) => (
        <Grid key={index} className={classes.tab}>
          <Typography className={classes.title}>{data.title}</Typography>
          <Typography className={classes.description}>{data.description}</Typography>
        </Grid>
      ))}
    </div>
  );
};

export default RoadMapTabs;
