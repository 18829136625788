import React from "react";

// MUI stuff
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FontSize from "../../../../utils/FontSize";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: FontSize.l,
    width: 453,
    lineHeight: 1.2,
    [theme.breakpoints.down("md")]: {
      fontSize: FontSize.size40,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: FontSize.size28,
      width: '100%',
    },
  },
  description: {
    fontSize: FontSize.m,
    color: theme.palette.secondary.textColor,
    marginTop: 25,
    width: 402,
    [theme.breakpoints.down("md")]: {
      fontSize: FontSize.size16,
    },
    [theme.breakpoints.down("sm")]: {
      // margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      width: '100%',
      marginTop: 10,
    },
  },
  btnStart: {
    height: 65,
    width: 200,
    borderRadius: 8,
    marginTop: 50,
    marginRight: 20,
    border: "1px solid #fff",
    fontSize: FontSize.size16,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      height: 55,
      width: 160,
      fontSize: 12,
      marginTop: 25,
      marginRight: 0,
      // marginBottom: 50,
      textAlign: "center",
    },
  },
}));

const RewardLeftSide = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title}>
        How to get GHOST rewards
      </Typography>
      <Typography className={classes.description}>
        You can earn GHOST by staking, which allows you to share in the fees generated by the Ghost blockchain. Staking has no minimum coin requirements.
      </Typography>{" "}
    </div>
  );
};

export default RewardLeftSide;
