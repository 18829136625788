import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FontSize from "../../../utils/FontSize";

const useStyles = makeStyles((theme) => ({
  body: {
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title: {
    fontSize: FontSize.l,
    marginBottom: 50,
    [theme.breakpoints.down("md")]: {
      fontSize: FontSize.size40,
    },
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      textAlign: 'center',
      fontSize: FontSize.size28,
      lineHeight: 1.2,
      marginBottom: 40,
    },
  },
  list: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 45,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
    [theme.breakpoints.down("xs")]: {
      display: "center"
    },
  },
  listNumber: {
    padding: "15px 20px",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "7px 13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: 1.2,
      padding: 0,
      marginTop: -5,
    },
  },
  listTitle: {
    fontSize: FontSize.m,
    width: 245,
    marginLeft: 25,
    marginTop: -5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: 1.2,
      width: '100%',
    },
  },
  listDescription: {
    width: 300,
    marginTop: 10,
    marginLeft: 80,
    color: theme.palette.secondary.textColor,
    fontSize: FontSize.sm,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      marginTop: 5,
      marginLeft: 0,
      width: '100%',
    },
  },
}));

const CreatedDistributed = () => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <Typography className={classes.title}>
        How is GHOST created and distributed?
      </Typography>
      {/* list 1  */}
      <div>
        <div className={classes.list}>
          <Typography
            className={classes.listNumber}
            style={{
              backgroundColor: "rgba(174, 255, 0, 0.1)",
              color: "#AEFF00",
            }}>
            1.
          </Typography>
          <div>
            <Typography className={classes.listTitle}>
              New GHOST coins are minted each block.
            </Typography>
          </div>
        </div>
        <Typography className={classes.listDescription}>
          In the beginning, 12 new GHOST coins are minted each block (every 2
          minutes).
        </Typography>
      </div>

      {/* list 2  */}
      <div>
        <div className={classes.list}>
          <Typography
            className={classes.listNumber}
            style={{
              backgroundColor: "rgba(61,169,252, 0.1)",
              color: "#3DA9FC",
            }}
          >
            2.
          </Typography>
          <div>
            <Typography className={classes.listTitle}>
              GHOST are split between Ghost Veterans.
            </Typography>
          </div>
        </div>
        <Typography className={classes.listDescription}>
          6 GHOST are split between Ghost Veterans.
        </Typography>
      </div>

      {/* list 3  */}
      <div>
        <div className={classes.list}>
          <Typography
            className={classes.listNumber}
            style={{
              backgroundColor: "rgba(174, 255, 0, 0.1)",
              color: "#AEFF00",
            }}
          >
            3.
          </Typography>
          <div>
            <Typography className={classes.listTitle}>
              GHOST is awarded to the staker.
            </Typography>
          </div>
        </div>
        <Typography className={classes.listDescription}>
          4 GHOST are awarded to the staker who mined the block.
        </Typography>
      </div>

      {/* list 4  */}
      <div>
        <div className={classes.list}>
          <Typography
            className={classes.listNumber}
            style={{
              backgroundColor: "rgba(61,169,252, 0.1)",
              color: "#3DA9FC",
            }}
          >
            4.
          </Typography>
          <div>
            <Typography className={classes.listTitle}>
              GHOST goes into a future development fund.
            </Typography>
          </div>
        </div>
        <Typography className={classes.listDescription}>
          2 GHOST goes into a pool used for future Ghost development and growth.
        </Typography>
      </div>
    </div>
  );
};

export default CreatedDistributed;
